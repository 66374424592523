import commonStore from '@store/commonStore'
import priceStore from '@store/priceStore'
import { areTwoBigNumbersEqual } from '@store/tradeboxStore'
import { useEffect } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'

export type PriceType = 'minPrice' | 'maxPrice'

const useTokenPrice = (
  pythId: string,
  tokenAddress: string,
  priceType: PriceType,
) => {
  const setPythIdsForWS = priceStore((store) => store.setPythIdForWS)
  const removePythIdForWS = priceStore((store) => store.removePythIdForWS)

  const priceFromWS = useStoreWithEqualityFn(
    priceStore,
    (state) => state.prices[pythId]?.[priceType],
    areTwoBigNumbersEqual,
  )

  const priceFromApi = useStoreWithEqualityFn(
    commonStore,
    (store) => store.prices[tokenAddress]?.[priceType],
    areTwoBigNumbersEqual,
  )

  useEffect(() => {
    setPythIdsForWS(pythId)
    return () => {
      removePythIdForWS(pythId)
    }
  }, [pythId])

  return priceFromWS || priceFromApi
}

export default useTokenPrice
