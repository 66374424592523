import Image from 'next/image'
import { getTokenUrl, tokenState } from './RenderTokenIcon'

interface Props {
  marketPoolName?: string
  indexTokenSymbol?: string
  size?: number
  state?: tokenState
}

const RenderSwapOnlyTokens: React.FC<Props> = ({
  marketPoolName,
  indexTokenSymbol,
  size = 20,
  state = 'active',
}) => {
  if (!marketPoolName) {
    return null
  }

  const tokens = marketPoolName.split('-').map((token) => token.toLowerCase())
  if (tokens.length !== 2) {
    return null
  }

  const [firstToken, secondToken] = tokens

  return (
    <div className="isolate min-w-fit">
      <Image
        src={getTokenUrl(firstToken, state)}
        alt={indexTokenSymbol || firstToken}
        width={size}
        height={size}
        className="relative z-20"
      />
      <Image
        src={getTokenUrl(secondToken, state)}
        alt={indexTokenSymbol || secondToken}
        width={size}
        height={size}
        className="-mt-2 ml-2"
      />
    </div>
  )
}

export default RenderSwapOnlyTokens
