import { BigNumber } from 'ethers'
import { getByKey } from 'gmx/lib/objects'
import groupBy from 'lodash/groupBy'
import { useMemo } from 'react'
import { isMarketDisabled } from 'utils/markets'
import { MarketsInfoData, MarketInfo } from '../markets'
import { TokensData, TokenData, convertToUsd } from '../tokens'

type SortedMarketResult = {
  markets: TokenData[]
  marketsInfo: MarketInfo[]
}

function useSortedMarketsWithIndexToken(
  marketsInfoData: MarketsInfoData | undefined,
  marketTokensData: TokensData | undefined,
): SortedMarketResult {
  const sortedMarketsWithIndexToken = useMemo((): SortedMarketResult => {
    if (!marketsInfoData || !marketTokensData) {
      return {
        markets: [],
        marketsInfo: [],
      }
    }

    const groupedMarketList = groupBy(
      Object.values(marketsInfoData),
      (market) =>
        market[market.isSpotOnly ? 'marketTokenAddress' : 'indexTokenAddress'],
    )

    const allMarkets = Object.values(groupedMarketList)
      .map((markets) => {
        return markets
          .filter((market) => {
            const marketInfoData = getByKey(
              marketsInfoData,
              market.marketTokenAddress,
            )!
            return !isMarketDisabled(marketInfoData)
          })
          .map(
            (market) => getByKey(marketTokensData, market.marketTokenAddress)!,
          )
      })
      .filter((markets) => markets.length > 0)

    const sortedGroups = allMarkets.sort((a, b) => {
      const totalMarketSupplyA = a.reduce((acc, market) => {
        const totalSupplyUsd = convertToUsd(
          market?.totalSupply,
          market?.decimals,
          market?.prices.minPrice,
        )
        acc = acc.add(totalSupplyUsd || BigNumber.from(0))
        return acc
      }, BigNumber.from(0))

      const totalMarketSupplyB = b.reduce((acc, market) => {
        const totalSupplyUsd = convertToUsd(
          market?.totalSupply,
          market?.decimals,
          market?.prices.minPrice,
        )
        acc = acc.add(totalSupplyUsd || BigNumber.from(0))
        return acc
      }, BigNumber.from(0))

      return totalMarketSupplyA.gt(totalMarketSupplyB) ? -1 : 1
    })

    const sortedMarkets = sortedGroups.map((markets) => {
      return markets.sort((a, b) => {
        const totalSupplyUsdA = convertToUsd(
          a.totalSupply,
          a.decimals,
          a.prices.minPrice,
        )!
        const totalSupplyUsdB = convertToUsd(
          b.totalSupply,
          b.decimals,
          b.prices.minPrice,
        )!
        return totalSupplyUsdA.gt(totalSupplyUsdB) ? -1 : 1
      })
    })

    const flattenedMarkets = sortedMarkets.flat().filter(Boolean)
    return {
      markets: flattenedMarkets,
      marketsInfo: flattenedMarkets.map(
        (market) => getByKey(marketsInfoData, market.address)!,
      ),
    }
  }, [marketsInfoData, marketTokensData])

  return sortedMarketsWithIndexToken
}

export default useSortedMarketsWithIndexToken
