import { Helmet } from 'react-helmet'

function SEO(props) {
  const { children, ...customMeta } = props
  const meta = {
    title: `RFX | Decentralized Perpetual Exchange`,
    description: 'RFX is a cutting-edge decentralised perpetual futures platform set to revolutionise the derivatives landscape on zkSync Era. RFX stands apart with its forward-thinking architecture, featuring a multi-pool model, advanced low-latency oracle networks, automated risk engine, and innovative veTokenomics. Built upon the principles of capital efficiency, LP risk management, and composability, RFX brings a Uniswap-style "on-rails" permissionless market creation to the derivatives realm.',
    image: '/rfx-og.png',
    type: 'exchange',
    ...customMeta,
  }
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="RFX" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@rfx_io" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
         <meta name="title" content={meta.title} data-react-helmet="true"/>
      </Helmet>
      {children}
    </>
  )
}

export default SEO
